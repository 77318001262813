import React from 'react';
// import { motion } from 'framer-motion';

const MiniContactPage = () => (
    <div className="contact-session-page">
      {/* <motion.div
        initial={{ scaleY: 0 }}
        animate={{ scaleY: 1 }}
        exit={{ scaleY: 1 }}
        transition={{ duration: 1.5, ease: [0.22, 1, 0.36, 1] }}
      > */}
        <div>
          <div className="profile-get-in-touch">
            <div className="emai-contact-div">
              <p className="hello-email-ribbon">SEND ME AN EMAIL</p>
              <a
                className="in-touch-comment-large"
                href="mailto:racheal.appiah-kubi@protonmail.com"
              >
                HELLO@RACHEAL
                {' '}
                <br />
                APPIAHKUBI.COM
              </a>
              <p className="in-touch-comment">A FULL STACK DEVELOPER WITH A BACKGROUND IN DATA SCIENCE AND MACHINE LEARNING</p>
            </div>
          </div>
        </div>
        <p className="connect-via-social-ribbon">OR CONNECT WITH ME ON SOCIALS</p>
        <div className="social-contact">
          <a href="https://github.com/coderacheal" target="blank">GITHUB</a>
          <a href="https://www.linkedin.com/in/racheal-appiah-kubi/" target="blank">LINKEDIN</a>
          <a href="https://medium.com/coderacheal" target="blank">MEDIUM</a>
        </div>
        <div className="contact-footer">
          <div>
            <p>Designed & Developed By</p>
            <p>Racheal Appiah-kubi</p>
          </div>
          <div className="footer-links-social">
            <div>
              <a href="https://github.com/coderacheal" target="blank">GITHUB</a>
            </div>
            <div>
              <a href="https://medium.com/@coderacheal" target="blank">LINKEDIN</a>
            </div>
          </div>
          <div>
            <p>Full Stack Data Engineer</p>
            <p>Portfolio @2023</p>
          </div>
        </div>
      {/* </motion.div> */}
    </div>
  );

export default MiniContactPage;
