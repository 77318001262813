// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".see-more-mouse_cursorContainer__L5L0W {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n}\n\n.see-more-mouse_cursor__fMdhf {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  text-align: center;\n  position: absolute;\n  z-index: 10;\n  width: 100px;\n  height: 100px;\n  border-radius: 50%;\n  font-size: 23px;\n  pointer-events: none;\n  cursor: none;\n  transition: opacity 0.3s, visibility 0s 5s;\n  background-color: rgba(0, 0, 0, 0.5);\n  color: white;\n}\n\n.see-more-mouse_cursor__fMdhf:active {\n  width: 80px;\n  height: 80px;\n}\n\n.see-more-mouse_cursor__fMdhf::after {\n  position: absolute;\n  width: 40px;\n  height: 40px;\n  border-radius: 50%;\n  opacity: 0.4;\n}\n", "",{"version":3,"sources":["webpack://./src/styles/see-more-mouse.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,uBAAuB;EACvB,mBAAmB;AACrB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,kBAAkB;EAClB,kBAAkB;EAClB,WAAW;EACX,YAAY;EACZ,aAAa;EACb,kBAAkB;EAClB,eAAe;EACf,oBAAoB;EACpB,YAAY;EACZ,0CAA0C;EAC1C,oCAAoC;EACpC,YAAY;AACd;;AAEA;EACE,WAAW;EACX,YAAY;AACd;;AAEA;EACE,kBAAkB;EAClB,WAAW;EACX,YAAY;EACZ,kBAAkB;EAClB,YAAY;AACd","sourcesContent":[".cursorContainer {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n}\n\n.cursor {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  text-align: center;\n  position: absolute;\n  z-index: 10;\n  width: 100px;\n  height: 100px;\n  border-radius: 50%;\n  font-size: 23px;\n  pointer-events: none;\n  cursor: none;\n  transition: opacity 0.3s, visibility 0s 5s;\n  background-color: rgba(0, 0, 0, 0.5);\n  color: white;\n}\n\n.cursor:active {\n  width: 80px;\n  height: 80px;\n}\n\n.cursor::after {\n  position: absolute;\n  width: 40px;\n  height: 40px;\n  border-radius: 50%;\n  opacity: 0.4;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"cursorContainer": "see-more-mouse_cursorContainer__L5L0W",
	"cursor": "see-more-mouse_cursor__fMdhf"
};
export default ___CSS_LOADER_EXPORT___;
