import React from 'react';
import ProjectTemplate from './components/ProjectTemplate';
import projectData from '../Home-page/data/projectData';

const Insight = () => {
  const insight = projectData[1];

  return (
    <div>
      <ProjectTemplate
        projectName={insight.projectName}
        projectDescription={insight.projectDescription}
        projectImage={insight.projectImage}
        projectYear={insight.projectYear}
        urlExtension={insight.urlExtension}
        projectGitHub={insight.projectGitHub}
        projectWebsite={insight.projectWebsite}
        miniIntro={insight.miniIntro}
        builtFor={insight.builtFor}
        projectSummaryOne={insight.projectSummaryOne}
        projectSummaryTwo={insight.projectSummaryTwo}
        carouselImages={insight.carouselImages}
        firstCarouselImage={insight.firstCarouselImage}
        secondCarouselImage={insight.secondCarouselImage}
        thirdCarouselImage={insight.thirdCarouselImage}
        nextProjectName={insight.nextProjectName}
        route={insight.route}
        backgroundColor={insight.backgroundColor}
        viewProject={insight.viewProject}
        summaryImage={insight.summaryImage}
        color={insight.color}
        height={insight.summaryImageHeight}
        width={insight.summaryImageWidth}
        role={insight.role}
        viewProjectbackgroundColor={insight.viewProjectbackgroundColor}
        viewProjectTextColor={insight.viewProjectTextColor}
        architecture={insight.architecture}
      />
    </div>
  );
};

export default Insight;
