import React from 'react';
import projects from '../../styles/projects.module.css';
import Header from './Header';
import ProjectImageAndInfo from './ProjectImageAndInfo';


const ProjectsPage = () => (
  <div className={projects.ProjectDiv}>
    <Header />
    <div>
      <ProjectImageAndInfo />
    </div>
    <div className={projects.projectsFooter}>
      <p>PROFESSIONAL PORTFOLIO</p>
      <p
        className={projects.listView}
      >
        {/* {' '} */}
        {/* {displayText} */}
      </p>
      <p>&copy;2023</p>
    </div>
  </div>
);
export default ProjectsPage;
