import React from 'react';
import Header from './Header';
import HelloHero from './HelloHero';
import ProjectCards from './ProjectCards';
import MiniAboutPage from './mini_pages/MiniAboutPage';
import MiniContactPage from './mini_pages/MiniContactPage';
// import Loader from './Loader';

const HomePage = () => (
  <div className="welcome-page">
    <main className="portfolio-content">
      <Header />
      <HelloHero />
      <ProjectCards />
      <MiniAboutPage />
      <MiniContactPage />
    </main>
  </div>
);

export default HomePage;
