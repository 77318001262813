import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCode, faBrain, faCertificate, faScrewdriverWrench, faLink
} from '@fortawesome/free-solid-svg-icons';
import { RoughNotation, RoughNotationGroup } from 'react-rough-notation';
import { useInView } from 'react-intersection-observer';
// import { motion } from 'framer-motion';
// import workspace from '../../assets/office set up.jpg';
import headshot from '../../assets/smile.jpg';
import Footer from './Footer';
import Header from './Header';
import NextProject from '../Indivual-Projects/components/NextProject';

const AboutPage = () => {
  const words = ['passionate', 'data', 'scientist'];
  const techStack = 'Tech Stack';
  const nextProjectName = 'Project Everest';
  const color = 'black';
  // const [visibleLetters, setVisibleLetters] = useState([]);

  const [isInView1, setIsInView1] = useState(false);
  const [ref1, inView1] = useInView({
    threshold: 0.4,
  });

  const [isInView2, setIsInView2] = useState(false);
  const [ref2, inView2] = useInView({
    threshold: 0.4,
  });
  const [isInView3, setIsInView3] = useState(false);
  const [ref3, inView3] = useInView({
    threshold: 0.4,
  });

  // Set the state to true when each element comes into view
  useEffect(() => {
    if (inView1) {
      setIsInView1(true);
    }
    if (inView2) {
      setIsInView2(true);
    }
    if (inView3) {
      setIsInView3(true);
    }

    // setVisibleLetters();
  }, [inView1, inView2, inView3]);

  return (
    <div className="about-me-main">
      <Header color={color} />
      {/* <motion.div
        initial={{ scaleY: 0 }}
        animate={{ scaleY: 1 }}
        exit={{ scaleY: 0 }}
        transition={{ duration: 1.5, ease: [0.22, 1, 0.36, 1] }}
      > */}
        <span className="long-arrow down-arrow">&darr;</span>
        <div className='about-selfie-image-div'>
          <h2 className="heroAboutMe">
            {words.map((word, index) => (
              <span key={words}>
                {word.split('').map((letter) => (
                  <span
                    key={word + letter}
                    className="passionate-dev"
                    aria-hidden="true"
                  >
                    {letter}
                  </span>
                ))}
                {index !== words.length - 1 && <br />}
              </span>
            ))}
          </h2>
          <div className="officeSetup-div">
              <img className="officeSetupImage" src={headshot} alt="workspace" />
          </div>
        </div>
        <div className="officeSetup-and-text">
          <RoughNotationGroup show className="roughNotation">
            <div className="more-about-me-div">
              <div className="section-description">
                <div>
                  <span>01/</span>
                  <p className="about-sub-section">Creativity</p>
                </div>

                <p className="more-about-me" ref={ref1}>
                  I am a
                  {' '}
                  {isInView1 ? (
                    <RoughNotation type="highlight" color="pink">
                      data scientist
                    </RoughNotation>
                  ) : null}
                  {' '}
                  passionate about building sleek applications and dat systems that leverage
                  {' '}
                  {isInView1 ? (
                    <RoughNotation type="highlight" color="rgba(159, 233, 180, 0.5)" strokeWidth={3}>
                      AI
                    </RoughNotation>
                  ) : null}
                  {' '}
                  and
                  {' '}
                  {isInView1 ? (
                    <RoughNotation type="highlight" color="rgba(159, 233, 180, 0.5)" strokeWidth={3}>
                      Machine Learning.
                    </RoughNotation>
                  ) : null}
                  {' '}
                  I love data the data as
                  much as the
                  {' '}
                  {isInView1 ? (
                    <RoughNotation type="highlight" color="rgba(237, 179, 216, 0.5)">
                      code.
                    </RoughNotation>
                  ) : null}
                  {' '}
                </p>
              </div>
              <div className="section-description">
                <div>
                  <span>02/</span>
                  <p className="about-sub-section">Career</p>
                </div>
                <p className="more-about-me" ref={ref2}>
                  I have 
                  {' '}
                  {isInView2 ? (
                    <RoughNotation type="highlight" color="rgba(159, 233, 180, 0.5)" strokeWidth={3}>
                      2+ years
                    </RoughNotation>
                  ) : null}
                  {' '}
                    of experience in machine learning, database management, visualization, cloud technologies and building applications in React, and a certified 
                  {' '}
                  {isInView2 ? (
                    <RoughNotation type="highlight" color="rgba(237, 179, 216, 0.5)" strokeWidth={3}>
                      Scrum master
                    </RoughNotation>
                  ) : null}
                  {' '}
                  In my previous role, I lead the development of the Azure Data Analyst curriculum for the <i>Bildungsgutschein</i> program in Germany, and have trained over 
                  {' '}
                  {isInView2 ? (
                    <RoughNotation type="highlight" color="rgba(159, 233, 180, 0.5)" strokeWidth={3}>
                      200+ people
                    </RoughNotation>
                  ) : null}
                  {' '}
                  in over
                  {' '}
                  {isInView2 ? (
                    <RoughNotation type="highlight" color="pink" strokeWidth={3}>
                      8 African countries
                    </RoughNotation>
                  ) : null}
                  {' '}
                  in machine learning and data science concepts.
                </p>
              </div>
              <div className="section-description">
                <div>
                  <span>03/</span>
                  <p className="about-sub-section">Interests</p>
                </div>
                <p className="more-about-me" ref={ref3}>
                  I am an unpublished author 😊, but I love to write <a href='https://docs.google.com/document/d/1q-1tPVEigd8kTwfHWu6pc8i4hyPFHGEz/edit?usp=sharing&ouid=108173902768735049260&rtpof=true&sd=true' target='_blank' rel="noreferrer"><FontAwesomeIcon className='project-link'icon={faLink}/></a>. I&lsquo;m  learning to play the
                  {' '}
                  {isInView3 ? (
                    <RoughNotation type="highlight" color="rgba(159, 233, 180, 0.5)">
                      violin
                    </RoughNotation>
                  ) : null}
                  {' '}
                  and I love to
                  {' '}
                  {isInView3 ? (
                    <RoughNotation type="circle" color="rgba(159, 233, 180, 0.5)" strokeWidth={3}>
                      sketch
                    </RoughNotation>
                  ) : null }
                  {' '}
                  on slow rainy days.
                  
                </p>
              </div>
            </div>
          </RoughNotationGroup>
        </div>
        <div>
          <h2 className="techStack">
            {techStack.split('').map((letter) => (
              <span key={techStack + letter}>{letter}</span>
            ))}
          </h2>
          <div className="techStackSessions">
            <div className="eachTechStackSessions">
              <div className="flip-card-inner">
                <div className="fa-container flip-card-front">
                  <FontAwesomeIcon icon={faCode} className="tech-fontawesome" />
                  <br />
                  <h3 className="stackTitle">Languages</h3>
                  <ul>
                    <li>Python</li>
                    <li>JavaScript</li>
                    <li>Ruby (Rails)</li>
                    <li>SQL</li>
                  </ul>
                </div>
                <div className="flip-card-back">
                  <p className="easterEgg">I am because we are - Ubuntu</p>
                </div>
              </div>
            </div>
            <div className="eachTechStackSessions">
              <div className="flip-card-inner">
                <div className="fa-container flip-card-front">
                  <FontAwesomeIcon
                    icon={faScrewdriverWrench}
                    className="tech-fontawesome"
                  />
                  <br />
                  <h3 className="stackTitle">Tools/Frameworks</h3>
                  <ul>
                    <li>React & Redux</li>
                    <li>APIs (FastAPI)</li>
                    <li>Git & GitHub</li>
                    <li>Docker</li>
                    <li>Linux</li>
                  </ul>
                </div>
                <div className="flip-card-back">
                  <p className="easterEgg">What ever you do, give a 100%, unless you are donating blood - Bill Murray</p>
                </div>
              </div>
            </div>
            <div className="eachTechStackSessions">
              <div className="flip-card-inner">
                <div className="fa-container flip-card-front">
                  <FontAwesomeIcon icon={faBrain} className="tech-fontawesome" />
                  <br />
                  <h3 className="stackTitle">AI/ML</h3>
                  <ul>
                    <li>Scikit Learn</li>
                    <li>HuggingFace</li>
                    <li>Streamlit</li>
                    <li>Gradio</li>
                  </ul>
                </div>
                <div className="flip-card-back">
                  <p className="easterEgg">I truly believe the internet is the greatest invention of my generation</p>
                </div>
              </div>
            </div>
            <div className="eachTechStackSessions">
              <div className="flip-card-inner">
                <div className="fa-container flip-card-front">
                  <FontAwesomeIcon icon={faCertificate} className="tech-fontawesome" />
                  <br />
                  <h3 className="stackTitle">Certifications</h3>
                  <ul>
                    <li>Certified AWS Cloud Practitioner</li>
                    <li>Certified Scrum Master (PSM I)</li>
                  </ul>
                </div>
                <div className="flip-card-back">
                  <p className="easterEgg">Did you find this easter egg? Send me an email. Let me know.</p>
                </div>
              </div>
            </div>
          </div>
          <NextProject
            nextProjectName={nextProjectName}
            route="/sunday"
            viewProject="View latest Project"
          />
        </div>
        <Footer />
      {/* </motion.div> */}
    </div>
  );
};

export default AboutPage;
